import Layout from "../../layouts/layout"
import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { graphql, Link, PageProps } from "gatsby"
import ResponsiveEmbed from "react-bootstrap/ResponsiveEmbed"
import Hero from "../../components/hero"
import secondsIcon from "../../images/seconds-pro-icon.svg"
import AppStore from "../../components/appstore"
import PlayStore from "../../components/playstore"
import Button from "react-bootstrap/Button"
import Rule from "../../components/rule"
import Img from "gatsby-image"
import { fluidWithIdentifier } from "../../helpers/image-helpers"
import { MetadataProps, SEOProps } from "../../types/types"
import SEO from "../../components/seo"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const BodyCoach: React.FunctionComponent<PageProps<
  MetadataProps & SEOProps
>> = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Joe Wicks uses Seconds Interval Timer app"
        description="Joe Wicks, the Body Coach, uses Seconds Interval Timer app
            to time his HIIT, Tabata and interval training workouts."
        image={data.seoImage.childImageSharp.sizes.src}
        favIconSizes={[16, 32, 48, 96]}
        favIconTemplate="/images/seconds/favicon-%s.png"
      />
      <Hero
        fluidImage={fluidWithIdentifier("bodycoachHero", data)}
        logo={secondsIcon}
        logoAlt="Seconds Pro Icon"
        heading="Joe Wicks uses Seconds Interval Timer"
        lead="The fitness star reveals his favourite workout tool."
      >
        <p>
          <AppStore url={data.site.siteMetadata.urls.seconds.appStore} />{" "}
          <PlayStore url={data.site.siteMetadata.urls.seconds.googlePlay} />
        </p>
        <Link to="/seconds-interval-timer">
          <Button>Learn more about Seconds</Button>
        </Link>
      </Hero>
      <Container className="py-5">
        <Row>
          <Col xs={12} lg={4}>
            <h2>P.E. with Joe</h2>
            <p>
              During the Covid-19 pandemic, while the schools are closed, Joe
              has been keeping the nation (the world?) in shape with his P.E.
              with Joe series on{" "}
              <OutboundLink href="https://www.youtube.com/watch?v=Mr49d6c5J8U&list=PLyCLoPd4VxBs-Mlkx9dLsfmFeCB13nEpC">
                YouTube
              </OutboundLink>
              .
            </p>
            <p>
              Each morning Joe performs a workout suitable for children with the
              help of{" "}
              <Link to="/seconds-interval-timer">Second Interval Timer</Link>{" "}
              keeping track of the workout in the background.
            </p>
            <p>
              <AppStore url={data.site.siteMetadata.urls.seconds.appStore} />{" "}
              <PlayStore url={data.site.siteMetadata.urls.seconds.googlePlay} />
            </p>
          </Col>
          <Col>
            <ResponsiveEmbed aspectRatio="16by9">
              <iframe
                title="P.E. with Joe"
                src="https://www.youtube.com/embed/DvGCaEhpfEk"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </ResponsiveEmbed>
          </Col>
        </Row>
      </Container>
      <Rule />
      <Container className="py-5">
        <Row>
          <Col xs={12} md={6}>
            <Img fluid={fluidWithIdentifier("joe", data)} />
          </Col>
          <Col>
            <h1>Joe Wicks: Why Seconds comes first</h1>

            <p className="lead">
              The fitness star reveals his favourite workout tool.
            </p>

            <p>
              <strong>Which apps do you use to get fit?</strong> We've all got
              our favourites, and fitness guru and all-round general health icon
              Joe Wicks is no different.
            </p>

            <p>
              The{" "}
              <OutboundLink href="https://www.amazon.com/Lean-15-15-Minute-Workouts-Healthy/dp/0062493663">
                Lean in 15
              </OutboundLink>{" "}
              author, TV presenter and{" "}
              <OutboundLink href="https://www.instagram.com/thebodycoach">
                Instagram
              </OutboundLink>{" "}
              icon has inspired many with his fitness regimes and healthy eating
              guides. When Wicks is workout out, there's only one app that he
              swears by, and it's probably one you've never heard of.
            </p>

            <p>
              &ldquo;I&rsquo;ve gone through quite a few fitness apps in the
              past,&rdquo; Wicks explains. &ldquo;The one I use more than
              everything though &mdash; the one I use almost every day and on
              every workout &mdash; is{" "}
              <Link to="/seconds-interval-timer">Seconds Interval Timer</Link>
              .&rdquo;
            </p>

            <p>
              Available on iPhone, iPad, and Apple Watch, Seconds is a
              fitness-friendly, interval trainer that offers an easy-to-read
              countdown timer and audible alerts for your upcoming exercises.
              If, like Wicks, you're into High Intensity Interval Training
              (HIIT) workouts it's essentially your best friend.
            </p>

            <p>
              &ldquo;It&rsquo;s a basic interval timer, but it&rsquo;s really
              clear,&rdquo; says Wicks.
            </p>

            <p>
              It&rsquo;s so easy to use,&rdquo; he adds. &ldquo;You create your
              intervals; your rest and work. It&rsquo;s not a die-hard fitness
              app, Seconds is just so simply. When you&rsquo;re on a workout
              phase it&rsquo;s green, when you&rsquo;re on a rest, it&rsquo;s
              red, it&rsquo;s really visual, so if it&rsquo;s far away and you
              haven&rsquo;t got your glasses on, you&rsquo;ve go the sound and
              the visual.&rdquo;
            </p>

            <p>
              It can adapt to your workout too. Wicks uses it for a while range
              of sessions, covering everything from crunches and curls to
              mountain climbers and burpees. For those of you who have watched
              Wicks's workout videos and exercise guides even if you can't see
              him using Seconds, changes are he's got the app running just out
              of frame.
            </p>

            <p>
              &ldquo;When I do my DVD or workouts, I&rsquo;ll still have it as a
              timer,&rdquo; he explains. &ldquo;I hear the, 'beep, beep, beep,
              1, 2, 3,' and it really motivates me to stick to an interval and
              push right until I hear that bell.&rdquo;
            </p>

            <blockquote className="blockquote">
              <p className="mb-0">
                It really motivates me to stick to an interval and push right
                until I hear that bell.
              </p>
              <footer className="blockquote-footer">Joe Wicks</footer>
            </blockquote>

            <p>
              Unlike some fitness apps, Seconds won&rsquo;t give you the biggest
              post-workout data breakdown, but that&rsquo;s why Wicks likes it
              so much.
            </p>

            <p>
              &ldquo;I&rsquo;m not really a stats man,&rdquo; he says.
              &ldquo;You can get really into it, there are plenty of people who
              love monitoring every step they take, and it&rsquo;s great
              technology, but for me, I&rsquo;m more about getting your heart
              rate up and working as hard as you can.
            </p>

            <p>
              &ldquo;Seconds is the one I truly engage in. You can save certain
              types of workout. It&rsquo;s basically got anyone who wants to
              monitor intervals and know how hard they&rsquo;re working for
              those exercise periods.&rdquo;
            </p>

            <p>
              Asides from fitness, which apps does Joe Wicks use most?
              &ldquo;It&rsquo;s basically Instagram, Facebook, Snapchat, Twitter
              and Whatsapp,&rdquo; says Wicks. &ldquo;My whole business is based
              on communication, so I have to go where the audience is.&rdquo;
            </p>

            <p>
              It&rsquo;s Seconds that always accompanies him in the gym though,
              and while you&rsquo;ll need to bring your own motivation to your
              exercise sessions, this exercise app can help you work towards
              your own fitness goals.
            </p>

            <p>
              Downloaded the app? These are Joe&rsquo;s training tips to enhance
              your first Seconds session.
            </p>

            <small>
              <OutboundLink href="https://apps.apple.com/ie/story/id1289316804">
                Originally published on the App Store on October 6, 2017.
              </OutboundLink>
            </small>
          </Col>
        </Row>
      </Container>
      <Rule />
    </Layout>
  )
}

export default BodyCoach

export const query = graphql`
  fragment fluidBackground on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  query BodyCoachPageQuery {
    site {
      siteMetadata {
        title
        emails {
          privacy
          support
        }
        urls {
          support
          seconds {
            appStore
            googlePlay
          }
          heavyset {
            appStore
          }
        }
      }
    }
    seoImage: file(relativePath: { eq: "images/seconds/promo.jpg" }) {
      childImageSharp {
        sizes {
          src
        }
      }
    }
    bodycoachHero: file(
      relativePath: { eq: "images/bodycoach/joe-wicks-kneeling.jpg" }
    ) {
      ...fluidBackground
    }
    joe: file(
      relativePath: { eq: "images/bodycoach/joe-wicks-app-store.jpg" }
    ) {
      ...fluidBackground
    }
  }
`
